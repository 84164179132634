import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { AppState, Auth0Provider } from '@auth0/auth0-react';

interface AppProps {
  children: React.ReactNode;
}

export const AuthProvider = (props: AppProps) => {
  // const domain = process.env.REACT_APP_AUTH0_DOMAIN!;
  // const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID!;
  // const audience = process.env.REACT_APP_AUTH0_AUDIENCE!;

  // const navigate = useNavigate();

  // const onRedirectCallback = (appState?: AppState) => {
  //   navigate(appState?.returnTo || window.location.pathname);
  // };

  // return (
  //   <Auth0Provider
  //     domain={domain}
  //     clientId={clientId}
  //     redirectUri={window.location.origin}
  //     onRedirectCallback={onRedirectCallback}
  //     audience={audience}
  //     cacheLocation="memory">
  //     {props.children}
  //   </Auth0Provider>
  // );

  return <>{props.children}</>;
};
